<template>
  <div class="w-full h-full pt-2">
    <div class="flex">
      <div class=" pr-5">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Employee
        </h1>
      </div>
      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div class="mx-3 px-5 mt-5 mb-4 pb-10">
      <div class="flex">
        <Sidebar
          :current-tab="currentTab"
          :tabs="tabs"
          @currentTab="currentTab = $event"
        />
        <PersonalInfo v-if="currentTab === 'Personal Info'" />
        <EmployeeDetails v-if="currentTab === 'Employment Details'" />
        <PayInfo v-if="currentTab === 'Pay Information'" />
        <EmployeeRole v-if="currentTab === 'Employee Roles'" />
        <Security v-if="currentTab === 'Account Settings'" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import PersonalInfo from "./personalInfo";
import EmployeeDetails from "./employmentDetails";
import PayInfo from "./payInformation";
import EmployeeRole from "./employeeRoles";
import Security from "./security";
import Sidebar from "./sideActions/sideBar";

export default {
  components: {
    PersonalInfo,
    EmployeeDetails,
    EmployeeRole,
    Breadcrumb,
    Sidebar,
    PayInfo,
    Security
  },
  data() {
    return {
      employeeDetails: null,
      breadcrumbs: [
        {
          disabled: false,
          text: "Employee",
          href: "Employee",
          id: "Employee"
        },
        {
          disabled: false,
          text: "View Profile",
          id: "View Profile"
        }
      ],
      tabs: [
        "Personal Info",
        "Employment Details",
        "Pay Information",
        "Employee Roles",
        "Account Settings"
      ],
      currentTab: "Personal Info"
    };
  }
};
</script>
